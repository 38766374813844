/* eslint-disable react/prop-types */
import React, { Suspense, createContext, useReducer } from 'react'
import { BrowserRouter, Route, Routes, HashRouter } from 'react-router-dom'
import './scss/style.scss'
import { reducer, initialState } from './reducers/Reducer'
import { PrivateRoute } from './PrivateRoute'

export const UserContext = createContext()

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Correo = React.lazy(() => import('./views/pages/login/Correo'))
const RecoveryPassword = React.lazy(() => import('./views/pages/login/recoveryPassword'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const Routing = () => {
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/recoveryPassword" name="recoveryPassword" element={<RecoveryPassword />} />
          <Route path="/login" name="Login Page" element={<Login />} />
          <Route path="/correo" name="Correo" element={<Correo />} />
          <Route path="/register" name="Register Page" element={<Register />} />
          <Route path="/404" name="Page 404" element={<Page404 />} />
          <Route path="/500" name="Page 500" element={<Page500 />} />
          <Route
            path="*"
            name="Inicio"
            element={
              <PrivateRoute>
                <DefaultLayout />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <Routing />
    </UserContext.Provider>
  )
}
export default App
